<template>
  <main>
    <section></section>
  </main>
</template>

<script>
export default {
  name: 'Redirect',
  mounted() {
    console.log('redirect page is mounted');
    console.log(document.referrer, 'document.referrer');
    if (document.referrer.split('/')[2] === window.location.hostname && this.$route.query.to) {
      window.location.href = atob(this.$route.query.to);
    } else {
      if (this.$route.query.to) delete this.$route.query.to;
      this.$router.push({
        name: 'home',
        params: { prefix: this.$route.params.prefix },
      });
    }
  },
};
</script>
